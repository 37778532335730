import React, { useState } from 'react';
import { getResponse } from './client/openai';

const starterMessages = [
  "How can I save energy at home?",
  "What are some ways to reduce energy consumption in the office?",
  "What are some tips for saving energy while traveling?",
  "Secrets to saving 10 GWh of energy in a day",
  "Tell a detailed story about a homeless man running on a human-sized hamster wheel alone contributes to 10GWh energy savings."
]

const Chatbot = () => {
  const [conversation, setConversation] = useState([
    { role: "assistant", content: "Hi! I'm your energy-saving assistant. Ask me anything about reducing energy consumption!" }
  ]);

  const [isBotTalking, setIsBotTalking] = useState(false);

  const handleUserInput = (event) => {
    event.preventDefault();
    const userInput = event.target.elements.userInput.value;
    event.target.reset();

    conversation.push({ role: "user", content: userInput });
    setConversation([...conversation]);
    handleResponse()
  }

  const handleResponse = async () => {
    setIsBotTalking(true);
    const stream = await getResponse(conversation);

    conversation.push({ role: "assistant", content: "" });
    setConversation([...conversation]);

    for await (const event of stream) {
      if (event.event === 'thread.message.delta') {
        const chunk = event.data.delta.content?.[0];
        if (chunk && 'text' in chunk && chunk.text?.value) {
          const textDelta = chunk.text?.value;
          const lastMessage = conversation[conversation.length - 1];
          lastMessage.content += textDelta;
          setConversation([...conversation]);
        }
      }
    }
    setIsBotTalking(false);
  }

  return (
    <div className='container'>
      <h1>Energy Conservation Chatbot</h1>
      {conversation.map(({role, content}, i) => (
        <div key={i}>
          <p>{{user: "User", assistant: "Bot"}[role]}:</p>
          <pre>{role === "assistant" && content === "" ? "Thinking..." : content}</pre>
        </div>
      ))}
      <form onSubmit={handleUserInput}>
        <input type="text" name="userInput" placeholder="Ask a question..." required />
        {" "}
        <button type="submit" disabled={isBotTalking}>Send</button>
      </form>

      {conversation.length === 1 && <>
        <p>Example questions:</p>
        {starterMessages.map((message, i) => (
          <div key={i}>
            <button className="starter" key={i} onClick={() => {
              conversation.push({ role: "user", content: message });
              setConversation([...conversation]);
              handleResponse();
            }}>
              {message}
            </button>
          </div>
        ))}
      </>
      }
    </div>
  );
}

export default Chatbot;