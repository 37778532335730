import OpenAI from "openai";

// eslint-disable-next-line no-useless-concat
const str = `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`

let client = null;
let thread = null;

(async () => {
  try {
    client =  new OpenAI({
      apiKey: str,
      dangerouslyAllowBrowser: true
    });
  } catch (error) {
    console.error("Error initializing OpenAI client:", error);
  }
})()

export async function getResponse(conversation) {
  if (!thread) {
    thread = await client.beta.threads.create({
      messages: conversation,
    });
  } else {
    await client.beta.threads.messages.create(thread.id, conversation.at(-1));
  }

  return client.beta.threads.runs.stream(thread.id, {
    assistant_id: "asst_DEXDRIz54xL9S40p1faqxk0L",
    stream: true,
  })
}
